html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

.loginCard {
  justify-content: center;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.loginCard > .logo {
  height: 100px;
  width: 100px;
}

.loginCard > input {
  height: 45px;
  width: 190px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15rem;
  line-height: 3rem;
}

.bm-menu a {
  color: #b8b7ad;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8rem;
}

.bm-item-list a {
  margin-left: 10px;
  font-weight: 700;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

